<template>
    <BasePopup v-if="isVisible" id="cookie-banner" @close="close">
        <p class="h3" style="margin-top: 0">
            {{ t.auth.cookie_banner_title }}
        </p>

        <p>
            {{ t.auth.cookie_banner_text }}
            <a
                :href="globalRoute.data_policy"
                @click.prevent="goToHref(globalRoute.data_policy)"
                rel="noreferrer noopener"
                >{{ t.auth.cookie_banner_text_2 }}</a
            >
        </p>

        <div class="button-bar">
            <a
                :href="globalRoute.data_policy"
                class="button button-light-grey button-high"
                @click.prevent="goToHref(globalRoute.data_policy)"
            >
                {{ t.auth.cookie_banner_save_selection }}
            </a>

            <a :href="'/' + globalRoute.locale" class="button button-primary button-high" @click.prevent="close">
                {{ t.auth.cookie_banner_accept_all }}
            </a>
        </div>

        <div style="margin-bottom: 10px"></div>
    </BasePopup>
</template>

<script>
import BaseButton from "@/components/Buttons/BaseButton.vue"
import BasePopup from "@/components/Frontend/BasePopup.vue"

export default {
    name: "CookieBanner",
    components: { BasePopup, BaseButton },
    props: [],
    data() {
        return {
            t: window.globalJs.translations,
            globalRoute: window.globalJs.route,
            isVisible: false,
        }
    },
    async created() {
        if (localStorage && localStorage.getItem("cookie-banner")) {
            //console.log('we have cookies')
        } else {
            //console.log('empty cookies')
            this.isVisible = true
        }
    },
    computed: {},
    methods: {
        goToHref(href) {
            localStorage.setItem("cookie-banner", "true")
            window.location.href = href
        },
        close() {
            this.isVisible = false
            localStorage.setItem("cookie-banner", "true")
            // localStorage.setItem("translation", "true")
            // this.notificationShown = false
        },
    },
}
</script>
